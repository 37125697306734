import React, { Fragment } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { renderAst } from "../components/ast/renderAst"
import ContentAst from "../components/content/contentAst"

export default ({
  data: {
    contentfulLayoutTemplate: {
      title,
      shortDescription,
      slug,
      canonical,
      seoTitle,
      blocks,
    },
  },
  location,
}) => {
  return (
    <Layout location={location} pageTitle={title}>
      <SEO
        title={seoTitle}
        description={shortDescription}
        pathname={slug}
        canonical={canonical}
        robots={`noindex, follow`}
      />
      <div className="container mx-auto px-6">
        <h1 className="text-4xl font-bold mt-6 mb-10">{title}</h1>
        {blocks !== null &&
          blocks.map((block) => {
            return (
              <Fragment key={block.id}>
                {block.__typename === "ContentfulBlock" && (
                  <div className="prose prose-lg max-w-none">
                    {renderAst(
                      ContentAst,
                      block.components[0].text.childMarkdownRemark.htmlAst
                    )}
                  </div>
                )}
              </Fragment>
            )
          })}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    contentfulLayoutTemplate(title: { eq: "Privacy Policy" }) {
      id
      title
      seoTitle
      shortDescription
      slug
      canonical
      blocks {
        __typename
        id
        title
        components {
          ... on Node {
            __typename
            id
            ... on ContentfulContentComponent {
              id
              title
              text {
                childMarkdownRemark {
                  htmlAst
                }
              }
            }
          }
        }
      }
    }
  }
`
